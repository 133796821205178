import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
const orderbookhost = environment.arbitrageapi;
const sorhost = environment.sorapi;

@Injectable({
    providedIn: 'root'
})
export class ApiservicesService {

    constructor(private http: HttpClient) {}

    authPostRequest(uri, data) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json')
        return this.http.post(orderbookhost + uri, data, { headers: headers })
    }

    orderbookPostRequest(uri, data, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json')
            .set('Authorization', token);
        return this.http.post(orderbookhost + uri, data, { headers: headers })
    }

    orderbookDeleteRequest(uri, data, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json')
            .set('Authorization', token);
        return this.http.delete(orderbookhost + uri, data)
    }
    orderbookgetRequest(uri, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);
        return this.http.get(orderbookhost + uri, { headers: headers })
    }

    sorgetRequest(uri, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/x-www-form-urlencoded')
            .set('Authorization', token);
        return this.http.get(sorhost + uri, { headers: headers })
    }

    sorPostRequest(uri, data, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json')
            .set('Authorization', token);
        return this.http.post(sorhost + uri, data, { headers: headers })
    }

    sorDeleteRequest(uri, data, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json')
            .set('Authorization', token);
        return this.http.delete(sorhost + uri, data)
    }

    sorPatchRequest(uri, data, token) {
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json')
            .set('Authorization', token);
        return this.http.patch(sorhost + uri, data, { headers: headers })
    }


}