// import { Injectable } from '@angular/core';
// import {
//     HttpEvent,
//     HttpInterceptor,
//     HttpHandler,
//     HttpRequest,
//     HttpErrorResponse,
//     HttpResponse,
//     HttpHeaders
// } from '@angular/common/http';
// import { Observable, throwError, of } from 'rxjs';
// import { catchError, map, tap, publishReplay, startWith, shareReplay } from 'rxjs/operators';
// import { Router } from '@angular/router';
// import { NotifyserviceService } from './notifyservice.service';

// export interface RequestCacheEntry {
//     url: string;
//     response: HttpResponse < any > ;
//     lastRead: number;
// }

// export abstract class RequestCache {
//     abstract get(req: HttpRequest < any > ): HttpResponse < any > | undefined;
//     abstract put(req: HttpRequest < any > , response: HttpResponse < any > ): void;
// }
// @Injectable({
//     providedIn: 'root'
// })
// export class HttpRequestInterceptor implements HttpInterceptor {

//     // constructor(private route:Router){}
//     cache = new Map < string, RequestCacheEntry > ();

//     constructor(private route: Router, private messenger: NotifyserviceService) {}

//     intercept(req: HttpRequest < any > , next: HttpHandler):
//         Observable < HttpEvent < any >> {
//             req = req.clone({
//                 withCredentials: true,
//             });

//             return next.handle(req).pipe(
//                 catchError((error: HttpErrorResponse) => {
//                     console.log("authhhhhhhhhhhhh")
//                     if (error.status == 401) {
//                         this.route.navigate(['/login']);
//                         localStorage.clear();
//                     }
//                     return throwError(error);
//                 })
//             );
//         }
// }


import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private route:Router){}
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      req = req.clone({
        withCredentials: true,
      });
      
      return next.handle(req) .pipe(
        catchError((error: HttpErrorResponse) => {
         if(error.status == 401){
            this.route.navigateByUrl('/login')
            localStorage.clear();
          }
           return throwError(() => error);
        })
      );
  }
}