import { Component, OnInit } from '@angular/core';
import { NotifyserviceService } from '../services/notifyservice.service';
import { ApiservicesService } from '../services/apiservices.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    constructor(private connectservice: ApiservicesService,
        private notifyService: NotifyserviceService,
        private actroute: ActivatedRoute,
        private route: Router) {}
    loginobj: any = {}
    ngOnInit(): void {
        if (this.actroute.snapshot.paramMap.get('crypted')) {
            this.loginobj.link = this.actroute.snapshot.paramMap.get('crypted')
        }
    }

    changepassword() {
        this.connectservice.authPostRequest('user/resetpassword', this.loginobj).subscribe((res: any) => {
            this.route.navigateByUrl('/login')
            this.notifyService.success(res.message)
        }, (err) => {
            this.notifyService.error(err.error.message)
        })
    }
}